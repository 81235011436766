let Global = {
    BLOOM_SCENE: 1,
    itemsToRayCastRight: [],
    itemsToRayCastLeft: [],
    itemsToRayCastDown: [],
    itemsToRayCastUp: [],
    itemsToCollect: [],
    energyTotal: 0,
    playCount: 0,
    fileType1: '',
    fileType2: '',
    fileType3: '',
    scoreTotal: 0,
    posX1: 0,
    posY1: 0,
    posX2: 0,
    posY2: 0,
    posX3: 0,
    posY3: 0,
    startPos: null,
    scale1: 1,
    scale2: 1,
    scale3: 1,
    userWidth1: 0,
    userWidth2: 0,
    userWidth3: 0,
    userHeight1: 0,
    userHeight2: 0,
    userHeight3: 0,
    moveExecuted: false,
    lastDirection: null,
    signedDirection: null,
    lastSignedDirection: null,
    lastMoveDirection: null,
    volumeBool: false,
    firstName: '',
    videoStream:'',
    canUpload: true,
    base64data: '',
    gameTime: 0,
    gameTry: 0,
    product_no: 0,
    rotateTO: null,
    canRotate: true,
    vBool: true,
    gameKey: null,
    loginStatus: false,
    gender: "male",
    firstname: '',
    lastname: '',
    imageNo: 1,
    streamStarted: false,
    ang1: 0,
    ang2: 0,
    currentPage: 'startPage',
    ang3: 0,
    lang: 'ENG',
    selectedType: 'email',
    message: '',
    uploadType: 'image',
    url: 'https://newsfeedsmartapp.com/oreoElHabba/assets/poster.png',
    movePossibleDirns: {
        'x': [1],
        'z': [0]
    },
    recipientType: 'Parents',
    recipient: '',
    countryCode: '',
    confirmBool:true,
    selectedMessage: 'message1',
    events: null,
    volumeBool: false,
    lastCheckPt: null,
    productArray: {
        wild_cherry_stick: {
            product_no: 0,
            status: 3,
        },
        air_rush_stick: {
            product_no: 0,
            status: 3
        },
        spearmint_stick: {
            product_no: 0,
            status: 3
        },
        wild_cherry_bottle: {
            product_no: 0,
            status: 3
        },
        air_rush_bottle: {
            product_no: 0,
            status: 3
        },
        spearmint_bottle: {
            product_no: 0,
            status: 3
        },
        tropical_bottle: {
            product_no: 0,
            status: 3
        },
        strawberry_and_lime_center_filled_infinity: {
            product_no: 0,
            status: 3
        },
        spearmint_center_filled_infinity: {
            product_no: 0,
            status: 3
        },
        tropical_infinity: {
            product_no: 0,
            status: 3
        },
        raspberry_and_lemon_double_layer_envelope: {
            product_no: 0,
            status: 3
        },
        watermelon_and_peach_double_layer_envelope: {
            product_no: 0,
            status: 3
        },
        berry_envelope: {
            product_no: 0,
            status: 3
        },
        mint_envelope: {
            product_no: 0,
            status: 3
        },
    },
    products: {
        wild_cherry_stick: 'WILD CHERRY STICK',
        air_rush_stick: 'AIR RUSH STICK',
        spearmint_stick: 'SPEARMINT STICK',
        wild_cherry_bottle: 'WILD CHERRY BOTTLE',
        air_rush_bottle: 'AIR RUSH BOTTLE',
        spearmint_bottle: 'SPEARMINT BOTTLE',
        tropical_bottle: 'X TROPICAL BOTTLE',
        strawberry_and_lime_center_filled_infinity: 'INFINITY STRAWBERRY AND LIME CENTER FILLED',
        spearmint_center_filled_infinity: 'INFINITY SPEARMINT CENTER FILLED',
        tropical_infinity: 'INFINITY TROPICAL',
        raspberry_and_lemon_double_layer_envelope: 'INFINITY RASPBERRY AND LEMON DOUBLE LAYER',
        watermelon_and_peach_double_layer_envelope: 'INFINITY WATERMELON AND PEACH DOUBLE LAYER',
        berry_envelope: 'INFINITY BERRY',
        mint_envelope: 'INFINITY MINT',
    },
    slectedProduct: 'air_rush_bottle',
    URL_CREATE: "https://newsfeedsmartapp.com/stimorol/api/click/webservice.php",
    URL_VUPDATE: "https://newsfeedsmartapp.com/stimorol/api/valueUpdator.php",
    // URL_GENERATE: "https://newsfeedsmartapp.com/OreoMaze/api/generate.php",
    URL_DATA: "https://newsfeedsmartapp.com/stimorol/api/webservice.php",
    URL_upload: "https://newsfeedsmartapp.com/stimorol/api/upload.php",
    URL_upload2: "https://newsfeedsmartapp.com/stimorol/api/uploadVideo.php",

    //  clickUpdator(clm) {
    //     // Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    //     //   uniqID: Global.U_ID,
    //     //   saveType: clm,
    //     //   status: Global.loginStatus,
    //     // });
    //     $.ajax({
	// 	url: 'https://newsfeedsmartapp.com/stimorol/api/valueUpdator.php', 
	// 	method: 'POST', 
	// 	dataType: 'json', 
	// 	data: {
	// 		saveType: clm,
	// 		uniqID:Global.U_ID
	// 	},
	// 	success: function(responseData) { 
			
	// 	},
	// 	error: function(jqXHR, textStatus, errorThrown) { 

	// 	}
	//   });
    // },
    buttonMusic(){
        document.querySelector('#buttonMusic').currentTime = 0;
        document.querySelector('#buttonMusic').play().catch(error => {
            // console.error("Playback failed:");
        });;
    },

    sliderAnimation(page1, page2, sign) {
        Global.currentPage = page2
        document.querySelector('.' + page1 + 'Container').style.height = 0;
        setTimeout(() => {
            document.getElementById(page1).style.display = "none";
            document.getElementById(page2).style.display = "flex";
            setTimeout(() => {
                document.querySelector('.' + page2 + 'Container').style.height = sign + '%';
            }, 100);

        }, 600);
        if (page2 == 'productPage') {
            Global.serverObj.sendData(
                'https://newsfeedsmartapp.com/stimorol/api/webservice.php',
                (response) => {

                    if (response.code == 200) {
                        Global.productArray = response.data;
                        $('.clientMsg').removeClass('active');
                        
                        for (let key in Global.productArray) {
                            if (Global.productArray.hasOwnProperty(key)) {
                                let product = Global.productArray[key];
                                // Clear previous active classes
                                //  console.log( Global.productArray[key]);
                                 
                        if ( Global.productArray[key].status == 1) {
                            $(`.${key} .clientMsg.approved`).addClass('active');
                            $('#select').addClass('disabled');
                            $(`.${key} .productImage`).css('opacity',0.5);

                        } else if (Global.productArray[key].status == 2) {
                            $(`.${key} .rejected`).addClass('active');
                            $('#select').addClass('disabled');
                            $(`.${key} .productImage`).css('opacity',0.5);
                        } else if ( Global.productArray[key].status == 0) { 
                            $(`.${key} .pending`).addClass('active');
                            $('#select').addClass('disabled');
                            $(`.${key} .productImage`).css('opacity',0.5);
                        } else {
                            $('#select').removeClass('disabled');
                            $('#select').off('click');
                            $(`.${key} .productImage`).css('opacity',1);
                        }

                            }
                        }
                      
                        
                        if (Global.productArray[Global.slectedProduct].status == 1) {
                            $(`.${Global.slectedProduct} .clientMsg.approved`).addClass('active');
                            $('#select').addClass('disabled');
                            $(`.${Global.slectedProduct} .productImage`).css('opacity',0.5);

                        } else if (Global.productArray[Global.slectedProduct].status == 2) {
                            $(`.${Global.slectedProduct} .rejected`).addClass('active');
                            $('#select').addClass('disabled');
                            $(`.${Global.slectedProduct} .productImage`).css('opacity',0.5);
                        } else if (Global.productArray[Global.slectedProduct].status == 0) {
                            $(`.${Global.slectedProduct} .pending`).addClass('active');
                            $('#select').addClass('disabled');
                            $(`.${Global.slectedProduct} .productImage`).css('opacity',0.5);
                        } else {
                            $('#select').removeClass('disabled');
                            $('#select').off('click');
                            $(`.${Global.slectedProduct} .productImage`).css('opacity',1);
                        }



                    }
                   


                },
                null,
                {
                    saveType: "productDetails",
                    uid: Global.U_ID,
                },
                "POST",
                null,
                false
            );
        }else{
          
        }
        if (page2 == 'loginPage' || page2 == 'productPage' || page2 == 'formPage' || page2 == 'uploadPage') {
            document.querySelector('.sunriseContainer').style.bottom = '-200%'

        } else {
            document.querySelector('.sunriseContainer').style.bottom = '0%'

        }
        if (page2 == 'uploadPage' && Global.vBool) {
            Global.vBool = false
        }
        if(page2=='sharePage'){
            Global.confirmBool=true
        }
        else {
            if (Global.videoStream) {
                Global.videoStream.getTracks().forEach(track => track.stop());
                Global.videoStream = null; // Clear the videoStream variable
            }
        }
        if (page2 == 'rewardsPage') {
            Global.serverObj.sendData(
                'https://newsfeedsmartapp.com/stimorol/api/webservice.php',
                (response) => {

                    if (response.code == 200) {
                        // console.log(response);
                        let data= response.data
                        $('.rewardContainer').css('opacity',0.5)
                       if(data.bonus_point==1){
                        $('#bonus_point').addClass('active')
                       }
                       if(data.r5_airtime==1){
                        $('#r5_airtime').addClass('active')
                        $('#r5_airtime .code').html(data.r5_coupon_code)
                        $('#r5_airtime .sec2').addClass('active')
                       }
                       if(data.r10_airtime==1){
                        $('#r10_airtime').addClass('active')
                        $('#r10_airtime .code').html(data.r10_coupon_code)
                        $('#r10_airtime .sec2').addClass('active')
                       }
                       if(data.r15_airtime==1){
                        $('#r15_airtime').addClass('active')
                        $('#r15_airtime .code').html(data.r15_coupon_code)
                        $('#r15_airtime .sec2').addClass('active')
                       }
                       if(data.grand_prize>0){
                        $('#bonusPrize').addClass('active')
                        $('#bonusPrize .count').html(`x ${data.grand_prize}`)
                       }

                    }
                    else {

                    }


                },
                null,
                {
                    saveType: "getRewards",
                    uid: Global.U_ID,
                },
                "POST",
                null,
                false
            );
        }
        if (page2 == 'startPage' && Global.loginStatus) {
            Global.serverObj.sendData(
                'https://newsfeedsmartapp.com/stimorol/api/webservice.php',
                (response) => {

                    if (response.code == 200) {
                        // console.log(response);
                        $('.points').html(response.total_points)
                        $('.whiteBar').css('width',`${response.percentage}%`)
                        $('.percentage').html(`${response.percentage}%`)

                    }
                    else {

                    }


                },
                null,
                {
                    saveType: "getPoints",
                    uid: Global.U_ID,
                },
                "POST",
                null,
                false
            );
        }
    },
    buttonAnimation(page1, page2, sign, button) {
        // document.getElementById(button).style.scale = 0.8
        // // document.querySelector('.'+button).style.display = 'block'
        // var counter2 = 0
        // var looper2 = setInterval(() => {
        //     counter2 += 1;
        //     if (counter2 === 21) {
        //         // counter2 = 1;

        //         clearInterval(looper2)
        //         // document.querySelector('.'+button).style.display = 'none'
        //     }
        //     // $("#"+button).find('.buttonEffect').hide()
        //     $("#"+button).find('.buttonEffect').attr('class',`buttonEffect button-effect-${counter2}`) 
        // }, 50);
        // let t1=setTimeout(() => {
        //     clearTimeout(t1)
        //     document.getElementById(button).style.scale = 1

        // }, 500);
        // let t2= setTimeout(() => {
        //     clearTimeout(t2)

        //     Global.sliderAnimation(page1, page2, sign)
        // }, 1000);
        Global.sliderAnimation(page1, page2, sign)

    },
    clickUpdater(clm) {
        $.ajax({
            url: 'https://newsfeedsmartapp.com/stimorol/api/clicks/webservice.php',
            method: 'POST',
            dataType: 'json',
            data: {
                saveType: 'clickUpdater',
                columnName: clm,
                click_id: Global.click_id
            },
            success: function (responseData) {

            },
            error: function (jqXHR, textStatus, errorThrown) {
                //  localStorage.clear()
                //  window.location.href = '/';
            }
        });
    },
    updateClicks(clm){
        Global.serverObj.sendData(Global.URL_VUPDATE, null, null, {
            uniqID: Global.U_ID,
            saveType: clm,
          });
    }


}

export {
    Global
}