import './sass/home.scss';
import { Global } from './objects/global';
import { Server } from "./objects/callServer";
import './components/uploadPage'
import './components/navbar'
import './components/loginPage'
import './components/popupPage'
import './components/formPage'
import './components/productPage'
import './components/startPage'
import './components/sidebarPage'
import './components/rewardsPage'
// import {
// 	isMobile,
// 	isMobileOnly
// } from 'mobile-device-detect';
// import $ from 'jquery';

// import './game'
// import  "./style.scss";
var that = this;
window.onload = function () {
	setTimeout(function () {
		document.querySelector(".loader").style.display = "none";
		function isMobilePhone() {
			const userAgent = navigator.userAgent || navigator.vendor || window.opera;
			
			// Check for Android phones but exclude Android tablets
			if (/android/i.test(userAgent) && !/tablet/i.test(userAgent)) {
			  return true;
			}
		  
			// Check for iPhone (exclude iPads)
			if (/iPhone/i.test(userAgent) && !window.MSStream) {
			  return true;
			}
		  
			return false;
		  }
		  
		  if (isMobilePhone()) {
			document.querySelector(".container").style.display = "flex";
		} else {
			document.querySelector(".desktopContainer").style.display = "flex";
		}
	}, 0);


}

$(document).ready(function () {
	// Global.isMobile = isMobile
	Global.serverObj = new Server();
	let isFirefox = navigator.userAgent.indexOf("Firefox") != -1;
	const userAgent = navigator.userAgent.toLowerCase();
	Global.isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
	document.querySelector('.loader').style.display = 'block'

	// const queryString2 = window.location.search;
	// const urlParams2 = new URLSearchParams(queryString2);
	// this.utm_source2 = urlParams2.get("utm_source");
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	let source = urlParams.get("utm_source");
	
	// $.ajax({
	// 	url: 'https://newsfeedsmartapp.com/stimorol/api/createUser.php', 
	// 	method: 'POST', 
	// 	dataType: 'json', 
	// 	data: {
	// 		saveType: 'create',
	// 		source:source
	// 	},
	// 	success: function(responseData) { 
	// 		if(responseData.code==200){
	// 			Global.U_ID=responseData.UID
	// 		localStorage.setItem('click_id',Global.click_id)
	// 		}
	// 	},
	// 	error: function(jqXHR, textStatus, errorThrown) { 
	// 	 localStorage.clear()
	// 	//  window.location.href = '/';
	// 	}
	//   });








	// const datepicker = document.getElementById('datepicker');

    // // Set the initial state
    // datepicker.type = "text"; // Start as 'text' to show the placeholder
    // datepicker.placeholder = datepicker.dataset.placeholder; // Set initial placeholder
    // datepicker.style.color = 'gray'; // Initial color for placeholder

    // // Automatically show the datepicker when clicking the input
    // datepicker.addEventListener('click', function() {
    //     this.type = 'date';  // Switch to 'date' input to show date picker
    //     this.removeAttribute('placeholder');  // Completely hide the placeholder
    //     this.style.color = 'black'; // Change color for date visibility
    //     this.showPicker();  // Open the date picker
    // });

    // // Handle the input event when a date is selected
    // datepicker.addEventListener('input', function() {
    //     if (this.value) {
    //         this.removeAttribute('data-placeholder'); // Hide data-placeholder completely
    //         this.style.color = 'black'; // Ensure date text is visible
    //     }
    // });

    // // Revert to 'text' input type if no date is selected
    // datepicker.addEventListener('blur', function() {
    //     if (!this.value) {
    //         this.type = 'text';  // Switch back to 'text'
    //         this.setAttribute('data-placeholder', 'DOB'); // Restore data-placeholder
    //         this.placeholder = this.dataset.placeholder; // Restore placeholder
    //         this.style.color = 'gray'; // Set placeholder color
    //     }
    // });

	Global.U_ID = localStorage.getItem('U_ID');
	Global.tkn = localStorage.getItem('tkn');
	Global.email = localStorage.getItem('email');
	Global.mobile = localStorage.getItem('mobile');

	Global.firstname = localStorage.getItem('firstname');
	Global.lastname = localStorage.getItem('lastname');
	// Global.click_id = localStorage.getItem('click_id');
	Global.dob = localStorage.getItem('dob');

	Global.click_id = localStorage.getItem('click_id');

	Global.gameKey = localStorage.getItem('gameKey');
	// console.log(Global.U_ID );
	// console.log(Global.gameKey );
	// console.log(Global.firstname );
	// console.log(Global.lastname );
	// console.log(Global.email );
	// console.log(Global.tkn );
	// console.log(Global.dob );
	// console.log(Global.mobile );
	// console.log(Global.click_id );
	
	if (Global.U_ID == '' || typeof (Global.U_ID) == "undefined" || Global.U_ID == null || Global.U_ID == 0 || 
		Global.gameKey == '' || typeof (Global.gameKey) == "undefined" || Global.gameKey == null || Global.gameKey == 0 ||
		Global.firstname == '' || typeof (Global.firstname) == "undefined" || Global.firstname == null || Global.firstname == 0 ||
		Global.lastname == '' || typeof (Global.lastname) == "undefined" || Global.lastname == null || Global.lastname == 0 ||
		Global.email == '' || typeof (Global.email) == "undefined" || Global.email == null || Global.email == 0 || Global.email=='undefined' ||
		Global.tkn == '' || typeof (Global.tkn) == "undefined" || Global.tkn == null || Global.tkn == 0 ||
		Global.dob == '' || typeof (Global.dob) == "undefined" || Global.dob == null || Global.dob == 0 ||
		Global.mobile == '' || typeof (Global.mobile) == "undefined" || Global.mobile == null || Global.mobile == 0 ||
		Global.click_id == '' || typeof (Global.click_id) == "undefined" || Global.click_id == null || Global.click_id == 0 
	) {
	$.ajax({
		url: 'https://newsfeedsmartapp.com/stimorol/api/clicks/webservice.php', 
		method: 'POST', 
		dataType: 'json', 
		data: {
			saveType: 'create',
			source:source
		},
		success: function(responseData) { 
			// console.log(responseData);
			
			if(responseData.code==200){
				Global.click_id=responseData.click_id
			localStorage.setItem('click_id',Global.click_id)
			document.querySelector(".container").style.display = "flex";
			document.querySelector(".container").style.left = "0";
			document.querySelector('.loader').style.display = 'none'
			document.querySelector(".overlay").style.display = "block";
			}
		},
		error: function(jqXHR, textStatus, errorThrown) { 
		 localStorage.clear()
		//  window.location.href = '/';
		}
	  });

	}else{
		Global.serverObj.sendData(
			'https://newsfeedsmartapp.com/stimorol/api/webservice.php',
			(response) => {

				if (response.code == 200) {
					document.getElementById('emailId').value = Global.email;
					document.getElementById('firstname').value = Global.firstname;
					document.getElementById('lastname').value = Global.lastname;
					document.getElementById('mobileNumber').value = Global.mobile;
          document.getElementById('datepicker').value = Global.dob;
		  document.querySelector('.bigin').innerHTML=`Hi<br> ${Global.firstname}`;

					document.querySelector('.profile').style.display='block';
                    document.querySelector('.menu').style.display='block';
					$("#firstname, #mobileNumber, #lastname,#datepicker,#emailId").prop('disabled', true);
					$("#firstname, #mobileNumber, #lastname,#datepicker,#emailId").css('color', '#a2a2a2');
					Global.loginStatus=true;
					// changeLanguage()
					// document.querySelector('#loginToBuy').style.display='none'  
					// window.myEmitter.emit(`addDisplayProducts`, Global.productArray)
					// window.myEmitter.emit(`show_page`, 'home_screen')   
					// Global.serverObj.sendData(
					// 	'https://newsfeedsmartapp.com/stimorol/api/webservice.php',
					// 	(response) => {

					// 		if (response.code == 200) {
					// 		Global.imageStatus=response.imageStatus
						

					// 		}
					// 		else {
								   
					// 		}
			
			
					// 	},
					// 	null,
					// 	{
					// 		saveType: "imageStatus",
					// 		uid: Global.U_ID,
					// 	},
					// 	"POST",
					// 	null,
					// 	false
					// );
					Global.serverObj.sendData(
						'https://newsfeedsmartapp.com/stimorol/api/webservice.php',
						(response) => {
		
							if (response.code == 200) {
								// console.log(response);
								$('.points').html(response.total_points)
								$('.whiteBar').css('width',`${response.percentage}%`)
								$('.percentage').html(`${response.percentage}%`)
		
							}
							else {
		
							}
		
		
						},
						null,
						{
							saveType: "getPoints",
							uid: Global.U_ID,
						},
						"POST",
						null,
						false
					);
					
				}
				else {
					   localStorage.clear();
					window.location.href = '/';
				}


			},
			null,
			{
				saveType: "logincheck",
				uid: Global.U_ID,
			},
			"POST",
			null,
			false
		);
	}
				

	this.vbool = true
	// var counter = 0
	// var looper = setInterval(() => {
	// 	counter += 1;
	// 	if (counter === 19) counter = 1;
	// 	document.querySelector("#divya").className = `top-diya-${counter}`
	// }, 150);
	// this.tapSound = document.querySelector("#buttonMusic");
	// document.addEventListener("visibilitychange", () => {
	// 	if (document.visibilityState === 'visible') {
	// 		if (Global.volumeBool) {
	// 			// document.querySelector("#BG_Music").play();

	// 		}
	// 	} else {
	// 		document.querySelector("#BG_Music").pause();
	// 		// document.querySelector('#GameBG_Music').pause()
	// 	}
	// });
	// document.querySelector('body').addEventListener('click', () => {
	// 	document.querySelector("#BG_Music").play();
	// 	if (this.vbool) {
	// 		this.vbool = false;
	// 		Global.volumeBool = true;
	// 		// document.querySelector("#BG_Music").play();
	// 		document.querySelector('#volumeImg').src = '/UI/Volume.png'

	// 	}
	// })
	// document.querySelector('#volume').addEventListener('click', () => {

	// 	if (Global.volumeBool) {
	// 		Global.volumeBool = false;
	// 		document.querySelector("#BG_Music").pause();
	// 		document.querySelector('#volumeImg').src = '/UI/Mute.png'
	// 	}
	// 	else {
	// 		Global.volumeBool = true;
	// 		document.querySelector("#BG_Music").currentTime = 0;
	// 		// document.querySelector("#BG_Music").play();
	// 		document.querySelector('#volumeImg').src = '/UI/Volume.png'
	// 	}
	// })





	this.checkbox1 = false;
	this.checkbox2 = false;

	// document.getElementById("download").addEventListener("click", () => {

	// 	clickUpdator('download')

	// 	// clickUpdator('download')
	// 	var mykeyVals = { uid: Global.U_ID, filename: "poster_" + Global.gameTry + ".png" };
	// 	// var saveData = $.ajax({
	// 	//     type: 'POST',
	// 	//     url: 'https://tatatealalghoda.newsfeedsmartapp.com/api/download.php',
	// 	//     data: mykeyVals,
	// 	//     dataType: "json",
	// 	//     success: function(resultData) {

	// 	//         console.log(uid)
	// 	//     },
	// 	//     // error: function(err) { console.log(err) }
	// 	// });


	// 	var efw = setTimeout(() => {
	// 		clearTimeout(efw);
	// 		var element = document.createElement("a"); /* console.log(downloadURL) */
	// 		element.setAttribute(
	// 			"href",
	// 			Global.url
	// 		);
	// 		// element.setAttribute(
	// 		// 	"href",
	// 		// 	"https://newsfeedsmartapp.com/ChakraGoldDeepavali/api/upload/1146282793/poster_1.jpg"
	// 		// );
	// 		element.setAttribute("download", `oreoCreations.jpg`);
	// 		document.body.appendChild(element);
	// 		element.click();
	// 		document.body.removeChild(element);

	// 	}, 250);
	// });

	function iOS() {
		// return true; 
		//remove this before go live, just for testing
		return [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.platform)
			// iPad on iOS 13 detection
			|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
	}
	var btn = document.querySelector('.wt');
	btn.addEventListener('click', async () => {

		// const image = await fetch(Global.url);

		// const blob = await image.blob();
		// const file = new File([blob], 'stimorol.png', { type: 'image/png' });
		// const filesArray = [file];
		// alert(filesArray);
        Global.updateClicks('wt_click')

		// if (iOS()) {
		// 	var shareData = {
		// 		files: filesArray,
		// 	}
		// } else {
		// 	var shareData = {
		// 		// title: "stimorol",
		// 		text: "https://www.stimorolflavourthemoment.co.za/",
		// 		// url: "https://www.stimorolflavourthemoment.co.za/",
		// 		// files: filesArray
		// 	}

		// }
		var shareData = {
			// title: "stimorol",
			text: "https://www.stimorolflavourthemoment.co.za/?utm_source=share",
			// url: "https://www.stimorolflavourthemoment.co.za/",
			// files: filesArray
		}
		try {
			await navigator.share(shareData)
			// alert('Share was successful.')
		} catch (err) {
			// alert(err)
		}
	});

	var btn2 = document.querySelector('.thred');
	btn2.addEventListener('click', async () => {

	
        Global.updateClicks('thred_click')

	
			var shareData = {
				text: "https://www.stimorolflavourthemoment.co.za/?utm_source=share",
		}

		try {
			await navigator.share(shareData)
			// alert('Share was successful.')
		} catch (err) {
			// alert(err)
		}
	});

	var btn3 = document.querySelector('.fb');
	btn3.addEventListener('click', async () => {

	
        Global.updateClicks('fb_click')

	
			var shareData = {
				text: "https://www.stimorolflavourthemoment.co.za/?utm_source=share",
		}

		try {
			await navigator.share(shareData)
			// alert('Share was successful.')
		} catch (err) {
			// alert(err)
		}
	});


});




