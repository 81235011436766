import { Global } from "../objects/global";

// var carousel = $(".carousel"),
//     items = $(".item"),
//     currdeg = 0;

// $(".next").on("click", { d: "n" }, rotate);
// $(".prev").on("click", { d: "p" }, rotate);

// document.querySelector('#select').addEventListener('click', () => {
//     Global.sliderAnimation('productPage', 'uploadPage', 100);
//     Global.isMobile = true;
//     let Global.videoStream;
//     let streamTO;

//     navigator.mediaDevices
//         .getUserMedia({
//             audio: false,
//             video: {
//                 width: Global.isMobile ? 1080 : 1080,
//                 height: Global.isMobile ? 1080 : 1080,
//                 facingMode: "environment",
//             },
//         })
//         .then((stream) => {
//             Global.videoStream = stream;
//             let videoObj = document.querySelector("#capture_video");
//             videoObj.srcObject = stream;
//             videoObj.addEventListener("loadedmetadata", () => {
//                 videoObj.play();
//                 streamTO = setTimeout(() => {
//                     Global.streamStarted = true;
//                     document.querySelector("#captureBtn").classList.add('active2');
//                 }, 200);
//             });
//         });
// });

// $('.homeBtn').click(() => {
//     Global.sliderAnimation(Global.currentPage, 'startPage', 100);
// });

// function rotate(e) {
//     if (e.data.d === "n") {
//         currdeg = currdeg - 60;
//     }
//     if (e.data.d === "p") {
//         currdeg = currdeg + 60;
//     }

//     carousel.css({
//         "-webkit-transform": "rotateY(" + currdeg + "deg)",
//         "-moz-transform": "rotateY(" + currdeg + "deg)",
//         "-o-transform": "rotateY(" + currdeg + "deg)",
//         "transform": "rotateY(" + currdeg + "deg)"
//     });

//     items.css({
//         "-webkit-transform": "rotateY(" + (-currdeg) + "deg)",
//         "-moz-transform": "rotateY(" + (-currdeg) + "deg)",
//         "-o-transform": "rotateY(" + (-currdeg) + "deg)",
//         "transform": "rotateY(" + (-currdeg) + "deg)"
//     });

//     // Calculate the currently active item and log its class name
//     var activeIndex = ((-currdeg / 60) % items.length + items.length) % items.length; // Ensure it's within bounds
//     var activeItem = items.eq(activeIndex);

//     // Log the class name of the active item
//     console.log("Active Item Class Name:", activeItem.attr('id'));
//     Global.selectedProduct = activeItem.attr('id');
//     console.log(activeIndex);
// }
/* var r = document.querySelector(':root');
r.style.setProperty('--card-size', '30vh');
r.style.setProperty('--card-size2', '45vh');
document.querySelector(".carousel").style.marginLeft = "auto";
// document.querySelector("#container2").style.overflow = "hidden";
document.querySelector(".carousel").classList.add("aligncenter"); */
// setSliders();

// function setSliders() {
//     function Card({ title, content }, i) {
//         const cardElement = document.createElement('div');
//         cardElement.className = 'card';



//         if (Global.language === "") {
//             var placeholderStyle = document.getElementById('placeholder-style');
//             var style = `::placeholder {
//             font-family: "Gotham" !important;
//             font-weight: 100;
//             color: #5A5A5A;font-size:1.9vh;
//         }`;
//             placeholderStyle.innerHTML = style;
//         } else {
//             var placeholderStyle = document.getElementById('placeholder-style');
//             var style = `::placeholder {
//             font-family: "GESSTextMedium" !important;
//             font-weight: 100;
//             color: #5A5A5A;
//         }`;
//             placeholderStyle.innerHTML = style;
//         }

//         switch (i) {
//             case 0:

//                 if (Global.language === "") {
//                     cardElement.innerHTML = `<div
//                         style="width: 100%;height: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                         <div style="height: 5%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                         </div>
//                         <div style="height: 15%;width: 100%;display: flex;flex-direction:column;justify-content: left;align-items: left;">
//                             What's your name?</div>
//                         <div style="height: 25%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                             <textarea  maxlength="15" class="textareaname" id="textarea1"
//                                 style="line-height: 25px;padding: 5%;width: 100%;background-color: rgba(255,255,255,1);border-radius: 1vh;outline: none;border: none;font-size: 2vh;font-family: 'Gotham';" placeholder="Enter your name" onkeyup="countChar(this,'contentarea0txt','15')"
//                                 type="text"></textarea>
//                                 <p  id="contentarea0txt" style="position: absolute;color: #ed0055;right: `+ ((Global.isMobile) ? `-16` : `6`) + `%;bottom: 39%;font-size: 2vh;">(0/15)</p>
//                                 </div>
//                         <div style="height: 10%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: left;">
//                         </div>
//                         <div style="height: 15%;width: 100%;display: flex;flex-direction:column;justify-content: left;align-items: left;">
//                             Where do you live?</div>
//                         <div
//                             style="height: 25%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                             <img id="dropdownimgID" style = "position: absolute;width: 20px;right: -20px;margin: auto;" src="./assets/Drop-down.png" alt="">
//                             <select name="cars" id="cars" style="width: 80%;
//                     border: none;border-radius: 0;text-align: left;height: 7vh;font-family: Gotham;font-size: 2vh;">
//                     <option style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;"
//                         value="Select Country">Select Country</option>
//                     <option style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="Bahrain">
//                     Bahrain</option>
//                     <option style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="Kuwait">Kuwait
//                     </option>
//                     <option style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="Oman">Oman
//                     </option>
//                     <option style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="Qatar">Qatar
//                     </option>
//                     <option style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="KSA">KSA
//                     </option>
//                     <option style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="UAE">UAE
//                     </option>
//                 </select>
//                             </div>
//                         <div
//                             style="height: 5%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                         </div>
//                     </div>`;



//                 } else {

//                     cardElement.innerHTML = `<div
//                     style="width: 100%;height: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;font-family:GESSTextMedium;">
//                     <div
//                         style="height: 5%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                     </div>
//                     <div
//                         style="height: 15%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: left;font-family:GESSTextMedium;font-size:2.5vh;" dir="rtl">ما الأسم؟</div>
//                     <div
//                         style="height: 25%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                         <textarea dir="rtl" maxlength="15" class="textareaname" id="textarea1"
//                             style="line-height: 30px;padding: 5%;width: 100%;background-color: rgba(255,255,255,1);border-radius: 1vh;outline: none;border: none;font-size: 2vh;font-family:GESSTextMedium !important;letter-spacing:1px !important;" placeholder="أدخل أسمك" onkeyup="countChar(this,'contentarea0txt','15')"
//                             type="text"></textarea>
//                             <p  id="contentarea0txt" style="position: absolute;color: #ed0055;left: `+ ((Global.isMobile) ? `12` : `6`) + `%;bottom: 39%;font-size: 2vh;font-family: 'Gotham';">(0/15)</p>
//                             </div>
//                     <div
//                         style="height: 10%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: left;">
//                     </div>
//                     <div
//                         style="height: 15%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: left;font-size:2.5vh;" dir="rtl">أين مكان إقامتكم؟</div>
//                     <div
//                         style="height: 25%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                         <img id="dropdownimgID" style = "position: absolute;width: 20px;left: 50px;margin: auto;" src="./assets/Drop-down.png" alt="">
//                         <select name="cars" id="cars" dir="rtl" class="selectClassRight" style="width: 80%; 
//                     border: none;border-radius: 0;height: 6vh;font-family: GESSTextMedium;font-size: 1.6vh;">
//                     <option class="optionClassRight" style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;"
//                         value="Select Country">اختر بلدك</option>
//                     <option class="optionClassRight" style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="Bahrain">
//                     البحرين</option>
//                     <option class="optionClassRight" style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="Kuwait">الكويت
//                     </option>
//                     <option class="optionClassRight" style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="Oman">سلطنة عمان
//                     </option>
//                     <option class="optionClassRight" style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="Qatar">دولة قطر
//                     </option>
//                     <option class="optionClassRight" style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="KSA">السعودية
//                     </option>
//                     <option class="optionClassRight" style="background-color: rgba(255,255,255,.8);border-radius: 0 !important;" value="UAE">الإمارات العربية المتحدة
//                     </option>
//                 </select>
//                         </div>
//                     <div
//                         style="height: 5%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                     </div>
//                 </div>`;
//                     cardElement.classList.add("fontClass");
//                     /* document.querySelector("textarea").style.letterSpacing = "1px"; */
//                     /* document.querySelector("textarea").classList.add("addletterSpace"); */

//                 }

//                 break;
//             case 1:
//                 if (Global.language === "") {
//                     cardElement.innerHTML = `<div
//                     style="width: 100%;height: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
                    
//                     <div
//                         style="height: 20%;width: 100%;display: flex;flex-direction:column;justify-content: left;align-items: left;text-align: left;" >
//                         What would you like as the scenery for your fantasy world?</div>
//                         <div
//                         style="height: 5%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                     </div>
//                     <div
//                         style="height: 75%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                         <textarea maxlength="70" class="contentarea1" id="textarea1"
//                             style="line-height: 20px;height: 90%;font-family: 'Gotham';
//                     width: 100%;background-color: rgba(255,255,255,1);border-radius: 2vh;outline: none;border: none;padding: 5%;" onkeyup="countChar(this,'contentarea1txt','70')"
//                             placeholder="Castle in the desert with candyfloss cloud, lollipops floating in the sky above a forest."
//                             type="text"></textarea>
//                             <p  id="contentarea1txt" style="position: absolute;color: #ed0055;right: `+ ((Global.isMobile) ? `-16` : `6`) + `%;bottom: -15%;font-size: 2vh;">(0/70)</p>
//                     </div>
//                 </div>`;
//                 } else {
//                     cardElement.innerHTML = `<div
//                     style="width: 100%;height: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
                    
//                     <div
//                         style="height: 20%;width: 100%;display: flex;flex-direction:column;justify-content: space-evenly;align-items: left;font-family:GESSTextMedium;font-size:2.5vh;" dir="rtl" >أيّ مشهد تريدون أن تروا في عالمكم الخياليّ؟</div>
//                         <div
//                         style="height: 5%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                     </div>
//                     <div
//                         style="height: 75%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                         <textarea dir="rtl" maxlength="70" class="contentarea1" id="textarea1"
//                         style="line-height: 20px;height: 90%;font-family: 'Gotham';
//                 width: 100%;background-color: rgba(255,255,255,1);border-radius: 2vh;outline: none;border: none;padding: 5%;font-family: GESSTextMedium !important;letter-spacing:1px !important;" onkeyup="countChar(this,'contentarea1txt','70')"
//                             placeholder="قلعة في الصّحراء مع سحابة من حلوى غزل البنات الزهريّة واللّوليبوبس تطير في السّماء فوق الغابة."
//                             type="text"></textarea>
//                             <p  id="contentarea1txt" style="position: absolute;color: #ed0055;left: `+ ((Global.isMobile) ? `12` : `6`) + `%;bottom: -15%;font-size: 2vh;font-family: 'Gotham';">(0/70)</p>
//                     </div>
//                 </div>`;
//                     cardElement.classList.add("fontClass");
//                 }
//                 break;

//             case 2:
//                 if (Global.language === "") {
//                     cardElement.innerHTML = `<div
//                     style="width: 100%;height: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
                    
//                     <div
//                         style="height: 20%;width: 100%;display: flex;flex-direction:column;justify-content: left;align-items: left;text-align: left;">
//                         What's your favourite candy flavour?</div>
//                         <div
//                         style="height: 5%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                     </div>
//                     <div
//                         style="height: 75%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                         <textarea maxlength="20" class="contentarea2" id="textarea1"
//                             style="line-height: 20px;height: 90%;font-family: 'Gotham';
//                     width: 100%;background-color: rgba(255,255,255,1);border-radius: 2vh;outline: none;border: none;padding: 5%;" onkeyup="countChar(this,'contentarea2txt','20')"
//                             placeholder="Chocolate, vanilla, cola, strawberry, etc."
//                             type="text"></textarea>
//                             <p  id="contentarea2txt" style="position: absolute;color: #ed0055;right: `+ ((Global.isMobile) ? `-16` : `6`) + `%;bottom: -15%;font-size: 2vh;">(0/20)</p>
//                     </div>
//                 </div>`;
//                 } else {
//                     cardElement.innerHTML = `<div
//                         style="width: 100%;height: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
                        
//                         <div
//                             style="height: 20%;width: 100%;display: flex;flex-direction:column;justify-content: space-evenly;align-items: left;font-family:GESSTextMedium;font-size:2.5vh;" dir="rtl">ما نكهتكم المفضّلة للحلوى؟</div>
//                             <div
//                             style="height: 5%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                         </div>
//                             <div
//                             style="height: 75%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                             <textarea maxlength="20" class="contentarea2" id="textarea1" dir="rtl"
//                                 style="line-height: 20px;height: 90%;font-family: 'Gotham';
//                         width: 100%;background-color: rgba(255,255,255,1);border-radius: 2vh;letter-spacing:1px !important;outline: none;border: none;padding: 5%;font-family: GESSTextMedium !important;" onkeyup="countChar(this,'contentarea2txt','20')"
//                                 placeholder="شوكولاطة، فانيلا، كولا، فراولة، إلخ."
//                                 type="text"></textarea>
//                                 <p  id="contentarea2txt" style="position: absolute;color: #ed0055;left: `+ ((Global.isMobile) ? `12` : `6`) + `%;bottom: -15%;font-size: 2vh;">(0/20)</p>
//                         </div>
//                     </div>`; cardElement.classList.add("fontClass");
//                 }
//                 break;
//             case 3:
//                 if (Global.language === "") {
//                     cardElement.innerHTML = `<div
//                     style="width: 100%;height: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
                    
//                     <div
//                         style="height: 20%;width: 100%;display: flex;flex-direction:column;justify-content: left;align-items: left;text-align: left;">
//                         Describe a character you wish to include</div>
//                         <div
//                         style="height: 5%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                     </div>
                    
//                         <div
//                         style="height: 75%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                         <textarea maxlength="70" class="contentarea3" id="textarea1"
//                             style="line-height: 20px;height: 90%;font-family: 'Gotham';
//                     width: 100%;background-color: rgba(255,255,255,1);border-radius: 2vh;outline: none;border: none;padding: 5%;" onkeyup="countChar(this,'contentarea3txt','70')"
//                             placeholder="Unicorn, camel, horse, etc."
//                             type="text"></textarea>
//                             <p id="contentarea3txt" style="position: absolute;color: #ed0055;right: `+ ((Global.isMobile) ? `-16` : `6`) + `%;bottom: -15%;font-size: 2vh;">(0/70)</p>
//                     </div>
//                 </div>`;

//                 } else {
//                     cardElement.innerHTML = `<div
//                     style="width: 100%;height: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
                    
//                     <div
//                         style="height: 20%;width: 100%;display: flex;flex-direction:column;justify-content: right;align-items: left;font-family:GESSTextMedium;font-size:2.5vh;" dir="rtl">اكتبوا وصفًا لشخصيّة تريدونها في الصّورة</div>
//                         <div
//                         style="height: 5%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                     </div>
//                         <div
//                         style="height: 75%;width: 100%;display: flex;flex-direction:column;justify-content: center;align-items: center;">
//                         <textarea maxlength="70" class="contentarea3" id="textarea1" dir="rtl"
//                             style="line-height: 20px;height: 90%;font-family: 'Gotham';letter-spacing:1px !important;
//                     width: 100%;background-color: rgba(255,255,255,1);border-radius: 2vh;outline: none;border: none;padding: 5%;font-family: GESSTextMedium !important;" onkeyup="countChar(this,'contentarea3txt','70')"
//                             placeholder="حصان وحيد القرن 'يونيكورن'، جمل، حصان، إلخ."
//                             type="text"></textarea>
//                             <p id="contentarea3txt" style="position: absolute;color: #ed0055;left: `+ ((Global.isMobile) ? `12` : `6`) + `%;bottom: -15%;font-size: 2vh;">(0/70)</p>
//                     </div>
//                 </div>`; cardElement.classList.add("fontClass");
//                 }
//                 break;
//         }

//         return cardElement;
//     }
//     var that = this;
//     // Create a Carousel component using a function
//     function Carousel({ children }) {
//         const carouselElement = document.querySelector(".carousel");
//         const leftButton = document.querySelector(".prev");
//         const rightButton = document.querySelector(".next");
//         const generateButton = document.querySelector(".generate");
//         const cardContainers = [];
//         let active = 0;

//         /* let dir = "0"; */

//         function setActive(index, dir) {
//             console.log("setActivesetActivesetActive",index)
//             Global.index = index;

//             if (dir === "left") {
//                /*  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
//                     saveType: "backbtn",
//                     uniqID: Global.U_ID,
//                     gameTry: Global.gameTry
//                 }, 'POST', null, false); */
//                 //that.setbtnanimation("behindbtn3")
//             } else if (dir === "right") {
//                 /* Global.serverObj.send(Global.URL_VUPDATE, null, null, {
//                     saveType: "nextbtn",
//                     uniqID: Global.U_ID,
//                     gameTry: Global.gameTry
//                 }, 'POST', null, false); */
//                 //that.setbtnanimation("behindbtn4")
//             }
//             var efwe = setTimeout(() => {
//                 clearTimeout(efwe);


//                 if (active === 0) {
                    
//                    /*  document.querySelector(".textareaname").classList.add("addletterSpace");
//                     document.getElementById("btnclickmp3").play();
//                     var name = document.querySelector(".textareaname");


//                     if (name.value === "" && dir != "left") {
//                         document.querySelector(".textareaname").classList.add("addShake");
//                         efwe = setTimeout(() => {
//                             clearTimeout(efwe);
//                             document.querySelector(".textareaname").classList.remove("addShake");
//                         }, 400);

//                         return;
//                     }
//                     if (Global.language === "") {
//                         if (!checkKeywords2(name)) {
//                             Global.currentInput = null;
//                             Global.currentInput = name;
//                             setDialogAlas();
//                             return;
//                         }
//                         fnaftername(name.value)
//                     } else {

//                         $.ajax({
//                             type: "POST",
//                             url: "https://newsfeedsmartapp.com/ChupaChups/api/languageconvert.php",
//                             dataType: "text",
//                             data: {
//                                 text: name.value,
//                                 user_id: Global.U_ID,
//                             },
//                             success: function (dt) {
//                                 if (!checkKeywords3(name, dt)) {
//                                     Global.currentInput = null;
//                                     Global.currentInput = name;
//                                     setDialogAlas();
//                                     return;
//                                 }
//                                 fnaftername(name.value)
//                             },
//                             error: function (err) {

//                             }
//                         });

//                     } */

//                 }

//                 else if (active === 1) {
                   
//                     /* document.querySelector(".contentarea1").classList.add("addletterSpace");
//                     document.getElementById("btnclickmp3").play();
//                     var content1 = document.querySelector(".contentarea1");
//                     if (content1.value === "" && dir != "left") {
//                         document.querySelector(".contentarea1").classList.add("addShake");
//                         var efw = setTimeout(() => {
//                             clearTimeout(efw);
//                             document.querySelector(".contentarea1").classList.remove("addShake");
//                         }, 400);

//                         return;
//                     }
//                     if (Global.language === "") {
//                         if (!checkKeywords2(content1)) {
//                             Global.currentInput = null;
//                             Global.currentInput = content1;
//                             setDialogAlas();
//                             return false;
//                         }
//                         Global.content1 = content1;
//                         fnaftercontent1();
//                     } else {
//                         $.ajax({
//                             type: "POST",
//                             url: "https://newsfeedsmartapp.com/ChupaChups/api/languageconvert.php",
//                             dataType: "text",
//                             data: {
//                                 text: content1.value,
//                                 user_id: Global.U_ID,
//                             },
//                             success: function (dt) {
//                                 if (!checkKeywords3(content1, dt)) {
//                                     Global.currentInput = null;
//                                     Global.currentInput = content1;
//                                     setDialogAlas();
//                                     return false;
//                                 }
//                                 Global.content1 = dt;
//                                 fnaftercontent1();
//                             },
//                             error: function (err) {

//                             }
//                         });
//                     } */
//                 }

//                 else if (active === 2) {
                    
//                     /* document.querySelector(".contentarea2").classList.add("addletterSpace");
//                     document.getElementById("btnclickmp3").play();
//                     var content2 = document.querySelector(".contentarea2");
//                     if (content2.value === "" && dir != "left") {
//                         document.querySelector(".contentarea2").classList.add("addShake");
//                         var efw = setTimeout(() => {
//                             clearTimeout(efw);
//                             document.querySelector(".contentarea2").classList.remove("addShake");
//                         }, 400);

//                         return;
//                     }
//                     if (Global.language === "") {
//                         if (!checkKeywords2(content2)) {
//                             Global.currentInput = null;
//                             Global.currentInput = content2;
//                             setDialogAlas();
//                             return false;
//                         }

//                         Global.content2 = content2;
//                         fnaftercontent2();
//                     } else {
//                         $.ajax({
//                             type: "POST",
//                             url: "https://newsfeedsmartapp.com/ChupaChups/api/languageconvert.php",
//                             dataType: "text",
//                             data: {
//                                 text: content2.value,
//                                 user_id: Global.U_ID,
//                             },
//                             success: function (dt) {
//                                 if (!checkKeywords3(content2, dt)) {
//                                     Global.currentInput = null;
//                                     Global.currentInput = content2;
//                                     setDialogAlas();
//                                     return false;
//                                 }

//                                 Global.content2 = dt;
//                                 fnaftercontent2();
//                             },
//                             error: function (err) {

//                             }
//                         });
//                     } */
//                 }

//                 else if (active === 3) {
//                     /* document.getElementById("btnclickmp3").play(); */

//                     active = 2;
//                     render();


//                 }
//             }, 250);


//         }

//         function fnaftername(str) {
//             Global.name = str;
//             var selectedvalue = $('#cars').val();

//             if (selectedvalue === "Select Country") {
//                 document.querySelector("#cars").classList.add("addShake");
//                 var efw = setTimeout(() => {
//                     clearTimeout(efw);
//                     document.querySelector("#cars").classList.remove("addShake");
//                 }, 400);

//                 return;
//             }
//             Global.country = selectedvalue;

//             active = Global.index;

//             render();
//         }

//         function fnaftercontent1() {
//             active = Global.index;

//             render();

//         }

//         function fnaftercontent2() {
//             active = Global.index;

//             render();



//         }

//         function fnafterall() {
//             active = index;

//             render();
//         }

//         function setDialogAlas() {
//             document.getElementById("dialogalas").style.zIndex = "100000000000000000000000000";
//             document.getElementById("dialogalas").style.touchAction = "auto";
//             document.getElementById("dialogalas").style.pointerEvents = "all";
//             document.getElementById("tryagainbtnKeyword").style.opacity = 1;
//             document.getElementById("dialogalas").style.left = "0";

//             document.getElementById("dialogalas").style.opacity = "1";
//             var f1 = setTimeout(() => {
//                 clearTimeout(f1);
//                 document.getElementById("dialogID").style.marginTop = "0";
//                 f1 = setTimeout(() => {
//                     clearTimeout(f1);
//                     document.getElementById("tryagainbtnKeyword").classList.add("activeScale1");
//                 }, 500);
//             }, 250);
//         }


//         function checkKeywords2(str2) {
//             var str = str2.value;
//             var arr = str.split(',').join(' ').split('.').join(' ').split(" ");

//             var bool = true;

//             for (var d = 0; d < Global.arrnegativewords.length; d++) {
//                 for (var dd = 0; dd < arr.length; dd++) {
//                     if ((arr[dd] != " ") && (arr[dd] != "")) {
//                         if ((arr[dd].trim().toLowerCase() === Global.arrnegativewords[d].trim().toLowerCase())) {
//                             bool = false;
//                             break;
//                         }
//                     }
//                 }
//             }

//             if (!bool) {
//                 str2.value = "";
//             }
//             return bool;
//         }

//         function checkKeywords3(str2, txt) {
//             /* var str = str2.value; */
//             var arr = txt.split(',').join(' ').split('.').join(' ').split(" ");

//             var bool = true;
//             for (var d = 0; d < Global.arrnegativewords.length; d++) {
//                 for (var dd = 0; dd < arr.length; dd++) {
//                     if ((arr[dd] != " ") && (arr[dd] != "")) {
//                         if ((arr[dd].trim().toLowerCase() === Global.arrnegativewords[d].trim().toLowerCase())) {
//                             bool = false;
//                             break;
//                         }
//                     }
//                 }
//             }

//             if (!bool) {
//                 str2.value = "";

//             }
//             return bool;
//         }

//         function render() {

//             cardContainers.forEach((container, i) => {
//                 const isActive = i === active;
//                 const offset = (active - i) / 3;
//                 const direction = Math.sign(active - i);
//                 const absOffset = Math.abs(active - i) / 3;
//                 const pointerEvents = isActive ? 'auto' : 'none';
//                 const opacity = Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1';
//                 const display = Math.abs(active - i) > MAX_VISIBILITY ? 'none' : (Global.isMobile) ? 'block' : 'flex';

//                 container.style.setProperty('--active', isActive ? '1' : '0');
//                 container.style.setProperty('--offset', offset);
//                 container.style.setProperty('--direction', direction);
//                 container.style.setProperty('--abs-offset', absOffset);
//                 container.style.setProperty('pointer-events', pointerEvents);
//                 container.style.setProperty('opacity', opacity);
//                 container.style.setProperty('display', display);
//             });
//             console.log(active,"sefuygjesfhygjef")
//             leftButton.style.display = active > 0 ? 'block' : 'none';
//             rightButton.style.display = active < cardContainers.length - 1 ? 'block' : 'none';
//             /* if (active === 3) {
//                 generateButton.style.display = "block";
//             } else {
//                 generateButton.style.display = "none";
//             } */
//         }

//         leftButton.addEventListener('click', () => setActive(active - 1, "left"));
//         rightButton.addEventListener('click', () => setActive(active + 1, "right"));
//         //generateButton.addEventListener('click', function () {

//        // });

//         [...children].forEach((child, i) => {
//             const cardContainer = document.createElement('div');
//             cardContainer.className = 'card-container';
//             cardContainer.appendChild(child.cloneNode(true));
//             carouselElement.appendChild(cardContainer);
//             cardContainers.push(cardContainer);
//         });

//         render();

//         return carouselElement;
//     }

//     // Define constants
//     const CARDS = 4;
//     const MAX_VISIBILITY = 3;

//     // Create an App function to initialize the application
//     function App() {
//         const appElement = document.getElementById('container2');
//         appElement.className = 'app';

//         const carouselElement = Carousel({
//             children: [...new Array(CARDS)].map((_, i) => (
//                 Card({ title: 'Card ' + (i + 1), content: 'Lorem ipsum dolor sit amet...' }, i)
//             ))
//         });

//         appElement.appendChild(carouselElement);

//         /* if (!Global.isMobile) {
//             carouselElement.classList.add("aligncenter");
//         } */

//         return appElement;
//     }

//     // Render the application
//     const appElement = App();
//     setTimeout(() => {
//         document.body.appendChild(appElement);

//     }, 0);

// }

import '@splidejs/splide/css/core';
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';

// Initialize the Splide instance
const splide = new Splide('#splide1', {
//   type: 'loop',
  interval: 2500,
//   pauseOnHover: false,
//   perPage: 2,
padding:'20%',
//   pauseOnFocus: false,
  
}).mount();

// Event listener for slide change
splide.on('move', (newIndex) => {
    const activeElement = splide.Components.Elements.slides[newIndex];
    // console.log('Active Slide Index:', newIndex);
    
    // Get the class name of the active slide element
    const classes = Array.from(activeElement.classList);
    // console.log('Active Slide Class List:', classes); // Log all classes
    // console.log(Global.productArray,activeElement.classList);


    // Find the class that is not the default classes
    const specificClass = classes.find(cls => cls !== 'splide__slide' && cls !== 'is-visible' && cls !== 'is-next' && cls !== 'is-active');
    
    if (specificClass) {
    //   console.log('Active Slide Class Name:', specificClass); // This will log "bottle_spearmint" or any other specific class
      document.querySelector('.itemName').innerHTML=Global.products[specificClass]
      Global.slectedProduct=specificClass
      const baseClassName = specificClass.split('_').pop(); // Split by underscore and take the first part

// console.log(baseClassName); 
document.querySelector('#product').src=`/UI/${baseClassName}.png`

// console.log(Global.productArray[classes[1]]['status']);
$('.clientMsg').removeClass('active');

let status=Global.productArray[classes[1]]['status']
if (status == 1 || status == 2 || status == 0) {
    $('#select').addClass('disabled');  // Add disabled class
    $(`.${Global.slectedProduct} .productImage`).css('opacity',0.5);

    if (status == 1) {
        $(`.${classes[1]} .clientMsg.approved`).addClass('active');

    }else if(status==2){
        $(`.${classes[1]} .clientMsg.rejected`).addClass('active');

    }else{
        $(`.${classes[1]} .clientMsg.pending`).addClass('active');
    }
} else {
    $('#select').removeClass('disabled');
    $('#select').off('click');  // Re-enable click functionality if previously disabled
    $(`.${Global.slectedProduct} .productImage`).css('opacity',1);

}

    }
});


document.querySelector('#select').addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    Global.sliderAnimation('productPage', 'uploadPage', 100);
    Global.isMobile = true;
    let streamTO;
    Global.updateClicks('select_click')
    Global.buttonMusic()
    navigator.mediaDevices
        .getUserMedia({
            audio: false,
            video: {
                width: Global.isMobile ? 1080 : 1080,
                height: Global.isMobile ? 1080 : 1080,
                facingMode: "environment",
            },
        })
        .then((stream) => {
            Global.videoStream = stream;
            let videoObj = document.querySelector("#capture_video");
            videoObj.srcObject = stream;
            videoObj.addEventListener("loadedmetadata", () => {
                videoObj.play();
                streamTO = setTimeout(() => {
                    Global.streamStarted = true;
                    // document.querySelector("#captureBtn").classList.add('active2');
                }, 200);
            });
        });
});

$('.homeBtn').click(() => {
    Global.buttonMusic()
    $('.btns').removeClass('active')
    Global.sliderAnimation(Global.currentPage, 'startPage', 100);
    document.querySelector('#rise').classList.add('active')
    Global.updateClicks('homeBtn_click')


});