import { Global } from "../objects/global";
import axios from 'axios'
var checkbox1 = false;
var checkbox2 = false
let get_otpBool=true;
let varify_otpBool=true;
setTimeout(() => {
    $('#mobileSelect').trigger('click')

}, 50);
document.querySelector('#start').addEventListener('click', () => {
    if(get_otpBool){
        Global.buttonMusic()
        if ((Global.email == '' || Global.email == null || Global.email == undefined || Global.email == 'undefined') && (Global.mobile == '' || Global.mobile == null || Global.mobile == undefined || Global.mobile == 'undefined')) {
            if (Global.selectedType == 'email') {
                validateEmail()
            } else {
                validatePhone()
            }
        } else {
            validateOTP()
        }
    }
    // console.log(Global.email,Global.mobile,'lkjhg');

})
document.querySelector('#pc').addEventListener('click',() => {
    window.open('https://privacy.mondelezinternational.com/za/en-ZA/privacy-policy/','_blank')
    Global.buttonMusic()
})
document.querySelector('#tnc').addEventListener('click',() => {
    document.querySelector('#popupIDTH').style.display='block'
    setTimeout(() => {
        document.querySelector('#popupIDTH').classList.add('active')
    }, 50);
    Global.buttonMusic()
})
$('.selctor').click(function () {
    Global.buttonMusic()
    $('.selctor').removeClass('active')
    $(this).addClass('active')
    if ($(this).attr('id') == 'emailSelect') {
        Global.selectedType = 'email'
        document.querySelector('#logonForm').classList.remove('active')

    } else {
        Global.selectedType = 'phone_number'
        document.querySelector('#logonForm').classList.add('active')
    }
})
function validateEmail() {
    let email = document.getElementById('email').value
    let otp_regx = /^(\+\d{1,3}[- ]?)?\d{8,8}$/;
    var regex =
        /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (email == "" || email == null) {
        $('.box ').css('display', 'none')
        $('.wrong ').css('display', 'block')
        $('.wrong ').find('.msg').html('Please enter Email.')
        document.querySelector('#popupPage').classList.add('active')
        return;
    } else if (!regex.test(email)) {
        $('.box ').css('display', 'none')
        $('.wrong ').css('display', 'block')
        $('.wrong ').find('.msg').html('Please enter valid Email.')
        document.querySelector('#popupPage').classList.add('active')
        return;
    } else {
        Global.email = email

        const formData = new FormData();
        formData.append('val', email);
        formData.append('selectedType', Global.selectedType);
        formData.append('userId', Global.click_id);
        get_otpBool=false
        axios.post(`https://newsfeedsmartapp.com/stimorol/api/get_otp.php`, formData)
            .then((response) => {
                let data = response.data;
                Global.U_ID = data.uid;
                // alert(response.data.otp)
                // console.log(response);
                Global.clickUpdater('start_beforeOTP_click')
                get_otpBool=true

                document.querySelector('#otp').style.display = 'block'

            })
            .catch((error) => {
                alert('somthing went wrong please try again');
                window.open('/')
            })
    }
}

function validatePhone() {
    let mobile = document.getElementById('mobileNumber2').value
    let mobile_regx = /^(\+\d{1,3}[- ]?)?\d{8,11}$/;


    if (mobile == "" || mobile == null) {
        $('.box ').css('display', 'none')
        $('.wrong ').css('display', 'block')
        $('.wrong ').find('.msg').html('Please enter number.')
        document.querySelector('#popupPage').classList.add('active')
        return;
    } else if (!mobile_regx.test(mobile)) {
        $('.box ').css('display', 'none')
        $('.wrong ').css('display', 'block')
        $('.wrong ').find('.msg').html('Please enter valid number.')
        document.querySelector('#popupPage').classList.add('active')
        return;
    } else {
        Global.mobile = mobile
        get_otpBool=false

        const formData = new FormData();
        formData.append('val', mobile);
        formData.append('selectedType', Global.selectedType);
        formData.append('userId', Global.click_id);
        axios.post(`https://newsfeedsmartapp.com/stimorol/api/get_otp.php`, formData)
            .then((response) => {
                let data = response.data;
                Global.U_ID = data.uid;
                // alert(response.data.otp)
                // console.log(response);
                Global.clickUpdater('start_beforeOTP_click')
                get_otpBool=true

                document.querySelector('#otp').style.display = 'block'

            })
            .catch((error) => {
                alert('somthing went wrong please try again');
                window.open('/')
            })
    }
}
function validateOTP() {
    let otp = document.getElementById('otp').value;
    let mobile_regx = /^(\+\d{1,3}[- ]?)?\d{6,6}$/;
    if (otp == "" || otp == null) {
        $('.box ').css('display', 'none')
        $('.wrong ').css('display', 'block')
        $('.wrong ').find('.msg').html('Please enter OTP.')
        document.querySelector('#popupPage').classList.add('active')


        return;
    } else if (!mobile_regx.test(otp)) {
        $('.box ').css('display', 'none')
        $('.wrong ').css('display', 'block')
        $('.wrong ').find('.msg').html('Please enter a valid OTP.')
        document.querySelector('#popupPage').classList.add('active')

        return;
    } else if (!checkbox1) {
        $('.box ').css('display', 'none')
        $('.wrong ').css('display', 'block')
        $('.wrong ').find('.msg').html('Please accept TnC')
        document.querySelector('#popupPage').classList.add('active')

        return;
    }
    else {
        // window.myEmitter.emit('validateOTP:login',otp)
        const formData = new FormData();
        formData.append('uid', Global.U_ID);
        if (Global.selectedType == 'email') {
            formData.append('val', Global.email);
        } else {
            formData.append('val', Global.mobile);
        }
        formData.append('otp', otp);
        formData.append('selectedType', Global.selectedType);
        formData.append('checkbox', checkbox2);
        let htk = "";
        // htk = getCookie("hubspotutk");
        // function getCookie(cookie) {

        //     return document.cookie.split(';').reduce(function (prev, c) {
        //         var arr = c.split('=');
        //         return (arr[0].trim() === cookie) ? arr[1] : prev;
        //     }, undefined);
        // }
        // formData.append('hubspotutk', htk);

        axios.post(`https://newsfeedsmartapp.com/stimorol/api/validate_otp.php`, formData)
            .then((response) => {
                let data = response.data;
                Global.clickUpdater('start_afterOTP_click')

                if (data.code == 200) {
                    Global.gameKey = data.gamekey;
                    Global.tkn = data.tkn;
                    $('.box ').css('display', 'none')
                    $('.success ').css('display', 'block')
                    document.querySelector('#popupPage').classList.add('active')
                    setTimeout(() => {
                        document.querySelector('#popupPage').classList.remove('active')
                    }, 1500);
                   setTimeout(() => {
                    if (data.userType == 'New') {
                        if (Global.selectedType == 'email') {
                            document.querySelector('#emailId').value = Global.email
                            document.querySelector('#emailId').classList.add('active')
                            $("#emailId").prop('disabled', true);
                        } else {
                            document.querySelector('#mobileNumber').value = Global.mobile
                            document.querySelector('#mobileNumber').classList.add('active')
                            $("#mobileNumber").prop('disabled', true);
                        }
                        Global.sliderAnimation('loginPage', 'formPage', 100)
                    } else {
                        Global.loginStatus = true;
                        Global.firstname = data.first_name;
                        Global.lastname = data.last_name;
                        Global.mobile = data.phone_number;
                        Global.email = data.email;
                        Global.dob = data.dob
                        // console.log(data);

                        Global.gameKey = data.gamekey;
                        Global.tkn = data.tkn
                        localStorage.setItem('U_ID', Global.U_ID);
                        localStorage.setItem('email', Global.email);
                        localStorage.setItem('firstname', Global.firstname);
                        localStorage.setItem('lastname', Global.lastname);
                        localStorage.setItem('mobile', Global.mobile);
                        localStorage.setItem('gameKey', Global.gameKey);
                        localStorage.setItem('tkn', Global.tkn);
                        localStorage.setItem('dob', Global.dob);
                        document.querySelector('.bigin').innerHTML=`Hi<br> ${Global.firstname}`;

                        document.getElementById('email').value = Global.email;
                        document.getElementById('firstname').value = Global.firstname;
                        document.getElementById('lastname').value = Global.lastname;
                        document.getElementById('mobileNumber').value = Global.mobile;
                        document.getElementById('datepicker').value = Global.dob;
                        document.querySelector('.profile').style.display = 'block';
                        document.querySelector('.menu').style.display = 'block';

                        $("#firstname, #mobileNumber, #lastname,#datepicker,#emailId").prop('disabled', true);
                        $("#firstname, #mobileNumber, #lastname,#datepicker,#emailId").css('color', '#a2a2a2');
                        document.querySelector('#emailId').value = Global.email
                        Global.sliderAnimation('loginPage', 'startPage', 100)

                    }
                   }, 2000);

                } else {
                    $('.box ').css('display', 'none')
                    $('.wrong ').css('display', 'block')
                    $('.wrong ').find('.msg').html('invalid otp')
                    document.querySelector('#popupPage').classList.add('active')
                }
            })




    }
}


// document.getElementById("dobField2").addEventListener("click", function() {
//     document.getElementById("datepicker").focus();  // Trigger focus on the date input
//   });



document.querySelector('#checkboxOne').addEventListener('click', () => {
    if (checkbox1) {
        checkbox1 = false
        document.querySelector('#checkboxOne').src = '/UI/cbOFF.png'
        // document.querySelector('#start').style.opacity = 0.5;
    }
    else {
        checkbox1 = true
        document.querySelector('#checkboxOne').src = '/UI/cbON.png'
        // document.querySelector('#start').style.opacity = 1;

    }
})
document.querySelector('#checkboxTwo').addEventListener('click', () => {
    if (checkbox2) {
        checkbox2 = false
        document.querySelector('#checkboxTwo').src = '/UI/cbOFF.png'
    }
    else {
        checkbox2 = true
        document.querySelector('#checkboxTwo').src = '/UI/cbON.png'

    }

})