import {
    Global
} from '../objects/global'
// this.activeDome = "dome";

document.getElementById("rise").addEventListener("click", () => {
    $('.btns').removeClass('active')
    Global.buttonMusic()
       if(!Global.loginStatus){
        document.querySelector('#rise').classList.add('active')
        Global.sliderAnimation(Global.currentPage, 'loginPage', 100)
        Global.clickUpdater('home_click')
       }else{
        if(Global.currentPage!='startPage'){
            Global.sliderAnimation(Global.currentPage, 'startPage', 100)
            document.querySelector('#rise').classList.add('active')
            Global.updateClicks('home_click')

        }
       }
  });
document.getElementById("normal").addEventListener("click", () => {
    $('.btns').removeClass('active')
    Global.buttonMusic()

    if(!Global.loginStatus){
        document.querySelector('#normal').classList.add('active')
        Global.sliderAnimation(Global.currentPage, 'loginPage', 100)
        Global.clickUpdater('camera_click')
       }else{
        if(Global.currentPage!='productPage'){
            Global.sliderAnimation(Global.currentPage, 'productPage', 100)
            document.querySelector('#normal').classList.add('active')
            Global.updateClicks('camera_click')

        }
       }
  });
document.getElementById("set").addEventListener("click", () => {
    $('.btns').removeClass('active')
    Global.buttonMusic()

    if(!Global.loginStatus){
        document.querySelector('#set').classList.add('active')
        Global.sliderAnimation(Global.currentPage, 'loginPage', 100)
        Global.clickUpdater('rewards_click')

       }else{
        if(Global.currentPage!='rewardsPage'){
            Global.sliderAnimation(Global.currentPage, 'rewardsPage', 100)
            document.querySelector('#set').classList.add('active')
            Global.updateClicks('rewards_click')

        }
       }
  });

