
import { Global } from '../objects/global';
import { Server } from "../objects/callServer";
// const video = document.getElementById('video');
//     const snapshot = document.getElementById('snapshot');
//     const overlay = document.querySelector('.overlay');
//     const captureButton = document.getElementById('capture');
// var Global.blobUrl='';
//     // Function to start the camera
//     function startCamera() {
//         const constraints = {
//             video: {
//                 facingMode: (navigator.userAgent.includes("Mobile")) ? { exact: "environment" } : "user" // Use environment (back) camera on mobile, user (front) camera on desktop
//             }
//         };

//         navigator.mediaDevices.getUserMedia(constraints)
//         .then(function (stream) {
//             video.srcObject = stream; // Set the video source to the stream
//         })
//         .catch(function (err) {
//             console.error("Error accessing camera: ", err);
//             alert("Could not access the camera. Please check your device and browser permissions.");
//         });
//     }

    // startCamera(); // Start the camera when the page loads

    // captureButton.addEventListener('click', function () {
    //     // Show overlay while capturing
    //     overlay.classList.remove('hidden');

    //     // Create an ImageCapture object
    //     const track = video.srcObject.getVideoTracks()[0];
    //     const imageCapture = new ImageCapture(track);

    //     // Capture the image
    //     imageCapture.takePhoto()
    //         .then(blob => {
    //             const url = URL.createObjectURL(blob);
    //             snapshot.src = url; // Set the captured image as the snapshot source
    //             Global.blobUrl=blob
    //             document.querySelector('#userImage').src=url
    //             snapshot.classList.remove('hidden'); // Show the captured image
    //             video.classList.add('hidden'); // Hide the video
    //             overlay.classList.add('hidden'); // Hide overlay
	// 			document.querySelector('.cameraContainer').style.display='none'
    //             document.querySelector('.displayImageContainer').style.display = 'block';

    //         })
    //         .catch(err => console.error('Error capturing image:', err));
    // });

    document.querySelector('.retake').addEventListener('click', () => {
        // Show the camera container and hide the display image container
        Global.buttonMusic()
        document.querySelector('.cameraContainer').style.display = 'block';
        document.querySelector('.displayImageContainer').style.display = 'none';
        
        // Reset the video visibility
        const video = document.getElementById('capture_video');
        video.classList.remove('hidden');
        
        // Hide the snapshot (captured image)
        const snapshot = document.getElementById('snapshot');
        snapshot.classList.add('hidden');
        
        // Hide the overlay (if visible)
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('hidden');
        Global.updateClicks('retake_click')

        // Restart the camera if needed (optional)
        // startCamera();
    });
    document.querySelector('#confirm').addEventListener('click', () => {
        // Assuming you have a blob (e.g., from a canvas or a file input)
    if(Global.confirmBool){
        Global.confirmBool=false
        const blob = new Blob([Global.blobUrl], { type: 'image/jpeg' }); // Replace with your actual image data
        // console.log(Global.productArray[Global.slectedProduct].product_no);
        Global.buttonMusic()
        // Create a FormData object to send the blob
        const formData = new FormData();
        formData.append('uid', Global.U_ID); // Replace with actual user ID
        formData.append('product', Global.slectedProduct); // Replace with actual user ID
        formData.append('product_no', parseInt(Global.productArray[Global.slectedProduct].product_no)+1); // Replace with actual user ID
        formData.append('file', blob, 'profile.jpg'); // Append the blob as a file
    
        // Send the data to the server
        fetch(Global.URL_upload, {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data);
            if (data.status === 200) {
                Global.sliderAnimation('uploadPage', 'sharePage', 100)
                document.querySelector('.cameraContainer').style.display = 'block';
                document.querySelector('.displayImageContainer').style.display = 'none';
                Global.url=data.url
                Global.productArray[Global.slectedProduct].product_no=parseInt(Global.productArray[Global.slectedProduct].product_no)+1
                // Reset the video visibility
                const video = document.getElementById('capture_video');
                video.classList.remove('hidden');
                
                // Hide the snapshot (captured image)
                const snapshot = document.getElementById('snapshot');
                snapshot.classList.add('hidden');
                
                // Hide the overlay (if visible)
                const overlay = document.querySelector('.overlay');
                overlay.classList.add('hidden');
                Global.updateClicks('confirm_click')

                // Restart the camera if needed (optional)
                // startCamera();       
                 } else {
                alert(data.message || 'Something went wrong.');
                // Global.sliderAnimation('uploadPage', 'sharePage', 100)
                // document.querySelector('.cameraContainer').style.display = 'block';
                // document.querySelector('.displayImageContainer').style.display = 'none';
                
                // // Reset the video visibility
                // const video = document.getElementById('video');
                // video.classList.remove('hidden');
                
                // // Hide the snapshot (captured image)
                // const snapshot = document.getElementById('snapshot');
                // snapshot.classList.add('hidden');
                
                // // Hide the overlay (if visible)
                // const overlay = document.querySelector('.overlay');
                // overlay.classList.add('hidden');
                
                // // Restart the camera if needed (optional)
                // startCamera();  
            }
        })
        .catch(err => console.error('Error during upload:', err));
    }
    });
    document.querySelector('.packs').addEventListener('click',() => {
        Global.sliderAnimation('uploadPage', 'productPage', 100)
        Global.updateClicks('packs_click')
        Global.buttonMusic()

    })
    
    const captureImg= (video, scaleFactor)=>{
        if (scaleFactor == null) {
          scaleFactor = 1;
        }
        var w = video.videoWidth * scaleFactor;
        var h = video.videoHeight * scaleFactor;
        var canvas = document.createElement("canvas");
        canvas.width = w;
          canvas.height = h;
      
        var ctx = canvas.getContext("2d");
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.scale(1, 1);
        /* if (Global.deviceType == "kiosk") {
          // to center
          ctx.rotate(Math.PI / 2);
        } else {
          ctx.scale(-1, 1);
        } */
        // rotate
        ctx.drawImage(video, -w / 2, -h / 2, w, h);
      
        return canvas.toDataURL("image/jpeg");
      }
      function base64ToBlob(base64, type = 'image/jpeg') {
        const byteCharacters = atob(base64.split(',')[1]); // Decode the Base64 string
        const byteNumbers = new Uint8Array(byteCharacters.length);
        
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        return new Blob([byteNumbers], { type: type });
    }
document.querySelector('#capture').addEventListener('click',() => {
    if(Global.streamStarted){
        const cookieImg= captureImg(document.querySelector("#capture_video"), 1);
        Global.buttonMusic()
        // console.log(cookieImg);
        const blob=base64ToBlob(cookieImg)
        const url = URL.createObjectURL(blob);
        document.querySelector('#snapshot').src = url;
        document.querySelector('#userImage').src = url;
        Global.blobUrl=blob
        // Show captured image and hide other elements
        document.querySelector('.cameraContainer').style.display = 'none';
        document.querySelector('.displayImageContainer').style.display = 'block';
        Global.updateClicks('capture_click')

    }
    
    // videoElement.classList.add('hidden');
    // overlay.classList.add('hidden');
})
