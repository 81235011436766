import { Global } from "../objects/global";

document.querySelector('#submit').addEventListener('click',() => {
    validateForm()
})

function validateForm() {
    let firstname = document.getElementById('firstname').value;
    let lastname = document.getElementById('lastname').value;
    let mobile = document.getElementById('mobileNumber').value;
    let email = document.getElementById('emailId').value;
    let dob = document.getElementById('datepicker').value;
    // let dob = '11-11-1111';
    const birthDate = new Date(dob);
    const today = new Date();
    const ageInMilliseconds = today - birthDate;
    const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25; // Approximate milliseconds per year
    const age = ageInMilliseconds / millisecondsPerYear;
    var regex =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const name_regx = /^[a-zA-Z ]{2,30}$/;
    let mobile_regx = /^(\+\d{1,3}[- ]?)?\d{8,11}$/;
    if (firstname.length <= 0) {
        showError('Please enter First Name')

        return;
    } else if (lastname.length <= 0) {
        showError('Please enter Last Name')


        return;
    } else if (mobile == "" || mobile == null) {
        showError('Please enter Phone Number')


        return;
    } else if (!mobile_regx.test(mobile)) {
        showError('Please enter valid Number')


        return;
    }else if (email == "" || email == null) {
        showError('Please enter Email')
        return;
    }else if (!regex.test(email)) {
        showError('Please enter valid Email')
        return;
    }
     else if (dob.length <= 0) {
        showError('Please enter DOB')
        return;
    }
    if (age < 13) {
        showError('You must be at least 13 years old.')
        return;
    }else {
        Global.firstname = firstname;
        Global.lastname = lastname;
        Global.mobile = mobile;
        Global.dob = dob
        Global.email = email

        // console.log(Global.U_ID);
    
        const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			let tempData = null;
			var ga_cid = "1844306653.1689247851";
			ga_cid = getCookie2('_ga');
	
			if (typeof ga_cid === "undefined" || ga_cid === null) {
				ga_cid = "1844306653.1689247851";
			}
		
			let utm_term = urlParams.get("utm_term");
			let utm_medium = urlParams.get("utm_medium");
			let utm_source = urlParams.get("utm_source");
			let utm_campaign = urlParams.get("utm_campaign");
			let utm_content = urlParams.get("utm_content");
			// window.htk = "s";
			window.htk = getCookie("hubspotutk");

            function getCookie(cookie) {

                return document.cookie.split(';').reduce(function (prev, c) {
                  var arr = c.split('=');
                  return (arr[0].trim() === cookie) ? arr[1] : prev;
                }, undefined);
              }
              
              function getCookie2(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                // console.log(name);
                for (let i = 0; i < ca.length; i++) {
                  let c = ca[i];
                  while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                  }
                  if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                  }
                }
                return "";
              }
              
        if(!Global.loginStatus){
            Global.serverObj.sendData(
                Global.URL_DATA,
                (response) => {
    
                    // console.log(response);
                    if (response.code == 200) {
                        localStorage.setItem('U_ID', Global.U_ID);
                        localStorage.setItem('email', Global.email);
                        localStorage.setItem('firstname', Global.firstname);
                        localStorage.setItem('lastname', Global.lastname);
                        localStorage.setItem('mobile', Global.mobile);
                        localStorage.setItem('gameKey', Global.gameKey);
                        localStorage.setItem('tkn', Global.tkn);
                        localStorage.setItem('dob', Global.dob);

                        document.querySelector('.profile').style.display = 'block';

                        document.getElementById('firstname').value=Global.firstname;
                        document.getElementById('lastname').value=Global.lastname;
                        document.getElementById('mobileNumber').value=Global.mobile;
                        document.getElementById('datepicker').innerHTML=Global.dob;
                        document.querySelector('.bigin').innerHTML=`Hi<br> ${Global.firstname}`;
                       
                        $("#firstname, #mobileNumber, #lastname,#datepicker").prop('disabled', true);
                        $("#firstname, #mobileNumber, #lastname").css('color', '#a2a2a2');
                        Global.sliderAnimation('formPage', 'startPage', 100)

                        Global.loginStatus=true;
                        // gtag('event', 'conversion', {
                        //     'allow_custom_scripts': true,
                        //     'u21': Global.lang,
                        //     'send_to': 'DC-9197940/oreo-001/engag00+standard'
                        //   });
                        //   document.getElementById("nscript").innerHTML = '<img src="https://ad.doubleclick.net/ddm/activity/src=9197940;type=oreo-001;cat=engag00;u21='+Global.lang+';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>'
                        //   fbq('track', 'CompleteRegistration');
                        //   ttq.track("CompleteRegistration");
    
                        // window.myEmitter.emit('saveProfile:profile', { firstname, lastname, mobile, shippingAddress })
    
                    }
                    else if(response.code==400){
                       showError(response.message)
                    }
    
    
                },
                null,
                {
                    first_name: Global.firstname,
                    last_name: Global.lastname,
                    phone_number: Global.mobile,
                    email: Global.email,
                    dob: Global.dob,
                    uid: Global.U_ID,
                    utm_term: utm_term,
                    utm_campaign: utm_campaign,
                    utm_medium: utm_medium,
                    utm_source: utm_source,
                    utm_content: utm_content,
                    ga_cid: ga_cid,
                    htk: window.htk,
                    checkbox:Global.checkbox2,
                    selectedType:Global.selectedType,
                    saveType: 'registerUser'
                },
                "POST",
		null,
		false
            );
        }else {
            Global.serverObj.sendData(
                'https://newsfeedsmartapp.com/oreoOCollection/api/webservice.php',
                (response) => {
    
                    // console.log(response);
                    if (response.code == 200) {

                 document.getElementById('address').value=Global.shippingAddress;
                        document.getElementById('streetName').innerHTML=Global.streetName;
                        document.getElementById('district').innerHTML=Global.district;
                        document.getElementById('city').innerHTML=Global.city;
                        document.getElementById('postalCode').value=Global.postalCode;                      
                        Global.compleateAddress = Global.shippingAddress.concat(", ", Global.streetName).concat(', ',Global.district).concat(', ',Global.city).concat(', ',Global.postalCode);
                        document.querySelector('.adressText').innerHTML=Global.compleateAddress;
                        $('.profile_sec').find('.userName').html(`${Global.firstname}`)
                        localStorage.setItem('shippingAddress', Global.shippingAddress);
                        localStorage.setItem('streetName', Global.streetName);
                        localStorage.setItem('district', Global.district);
                        localStorage.setItem('city', Global.city);
                        localStorage.setItem('postalCode', Global.postalCode);
    // console.log(Global.postalCode,Global.compleateAddress);
                        window.myEmitter.emit('saveProfile:profile', { firstname, lastname, mobile, shippingAddress })
    
                    }
                    else if(response.code==400 && response.message=='Phone number alreday registered'){
                        $("#pError").html("Phone number alreday registered.");
                        document.querySelector(".profileError").style.opacity = 1;
                    }
    
    
                },
                {
                    short_address: Global.shippingAddress,
                    street_building: Global.streetName,
                    district: Global.district,
                    city: Global.city,
                    postal_code: Global.postalCode,
                    uid: Global.U_ID,
                    saveType: 'updateAddress'
                }
            );
        }
        
    }
}

function showError(msg){
    $('.box ').css('display','none')
    $('.wrong ').css('display','block')
    $('.wrong ').find('.msg').html(msg)
    document.querySelector('#popupPage').classList.add('active')
}