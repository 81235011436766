import { Global } from "../objects/global"

document.querySelector('.profile').addEventListener('click',() => {
    Global.sliderAnimation(Global.currentPage,'formPage',100)
    document.querySelector('#submit').style.display='none'
    document.querySelector('#okay').classList.add('active')
    Global.updateClicks('profile_click')
    Global.buttonMusic()
})
document.querySelector('#okay').addEventListener('click',() => {
    Global.sliderAnimation(Global.currentPage,'startPage',100)
    Global.updateClicks('okay_click')
    Global.buttonMusic()

})
document.querySelector('.menu').addEventListener('click',() => {
    document.querySelector('#sidebarPage').style.display='flex'
    Global.clickUpdater('sidebar_click')
    Global.buttonMusic()
    setTimeout(() => {
        document.querySelector('#sidebarPage').classList.add('active')
    }, 50);
 
})

document.querySelector('.card').addEventListener('click',() => {
if(!Global.loginStatus){
    Global.sliderAnimation('startPage', 'loginPage', 100)
}
})