import { Global } from "../objects/global";
let r5flag=true;
let r10flag=true;
let r15flag=true;
$('#r5_copy').click(function(){
   if(r5flag){
    let code=$('#r5_code').html()
    navigator.clipboard.writeText(code)
    .then(() => {
        // console.log("Text copied to clipboard!");
        $('#r5_copy').html('Copied to clipboard')
        r5flag=false
        setTimeout(() => {
            $('#r5_copy').html('Tap to copy')
            r5flag=true
        }, 1000);
    })
    .catch(err => {
        console.error("Failed to copy text: ", err);
    });
   }
})
$('#r10_copy').click(function(){
   if(r10flag){
    let code=$('#r10_code').html()
    navigator.clipboard.writeText(code)
    .then(() => {
        $('#r10_copy').html('Copied to clipboard')
        r10flag=false
        setTimeout(() => {
            $('#r10_copy').html('Tap to copy')
            r10flag=true
        }, 1000);    })
    .catch(err => {
        console.error("Failed to copy text: ", err);
    });
   }
})
$('#r15_copy').click(function(){
   if(r15flag){
    let code=$('#r15_code').html()
    navigator.clipboard.writeText(code)
    .then(() => {
        $('#r5_copy').html('Copied to clipboard')
        r15flag=false
        setTimeout(() => {
            $('#r15_copy').html('Tap to copy')
            r15flag=true
        }, 1000);
    })
    .catch(err => {
        console.error("Failed to copy text: ", err);
    });
   }
})
$('.info').click(function(){
    $('.box ').css('display', 'none')
        $('.rewardsInfo ').css('display', 'block')
        $('.rewardsInfo ').find('.msg').html('<b>How to redeem your airtime voucher:</b><br><br>1. Dial the code on your handset.<br>2. Your airtime will be automatically loaded onto your device.<br><br>Expiry Date: Be sure to redeem your airtime before 30 September 2027.')
        document.querySelector('#popupPage').classList.add('active')

})