import { Global } from "../objects/global";

document.querySelector('.close').addEventListener('click',() => {
    document.querySelector('#sidebarPage').classList.remove('active')
    setTimeout(() => {
        document.querySelector('#sidebarPage').style.display='none'
    }, 500);
 
})

document.querySelector('.tc').addEventListener('click',() => {
    document.querySelector('#popupIDTH').style.display='block'
    Global.clickUpdater('tc_click')
    Global.buttonMusic()
    setTimeout(() => {
        document.querySelector('#popupIDTH').classList.add('active')
    }, 50);
})
document.querySelector('#close2').addEventListener('click', () => {
    document.querySelector('#popupIDTH').classList.remove('active')
    setTimeout(() => {
        document.querySelector('#popupIDTH').style.display='none'
    }, 500);
});
document.querySelector('.pc').addEventListener('click',() => {
    Global.clickUpdater('pc_click')
    Global.buttonMusic()
    window.open('https://privacy.mondelezinternational.com/za/en-ZA/privacy-policy/','_blank')
})
document.querySelector('.faq').addEventListener('click',() => {
    Global.clickUpdater('faq_click')
    Global.buttonMusic()
    window.open('https://forms.office.com/e/BvakeRj005','_blank')
})

document.querySelector('.overview').addEventListener('click',() => {
    document.querySelector('#sidebarPage').classList.remove('active')
    Global.clickUpdater('overview_click')
    Global.buttonMusic()
    setTimeout(() => {
        document.querySelector('#sidebarPage').style.display='none'
        Global.sliderAnimation(Global.currentPage,'aboutPage',100)
    }, 500);
})